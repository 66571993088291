import React, { useState , useEffect } from "react"
import { Collapse, Typography, Form, Checkbox, Button } from 'antd';
import { t } from '../../../helpers/translation'
import './styles.scss'
import { getServiceCategories } from '../../../services/coupons'

const { Panel } = Collapse;
const secondaryCategoriesOptions = [
  {
    value: 0,
    label: t('coupon.enums.nothingParticular'),
  },
  {
    value: 1,
    label: t('coupon.enums.menOnly'),
  },
  {
    value: 2,
    label: t('coupon.enums.womenOnly'),
  },
]

export const Filter = ({tabPaneKey, onGetCoupons, onGetMenus, activeTab}) => {
  const [menuCategories, setMenuCategories] = useState()
  const [form] = Form.useForm();

  const onSubmitFilter = () => {
    form.validateFields()
        .then((values) => {
          onGetCoupons(values);
          onGetMenus(values);
        })
  };

  useEffect(() => {
    getServiceCategories().then((response) => {
      const categories = response.data;
      setMenuCategories(Object.keys(categories).map((key) => ({ value: key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`), label: categories[key] })))
    })
  }, [])

  useEffect(() => {
    if (tabPaneKey === activeTab) {
      onSubmitFilter();
    }
  }, [activeTab])

  const genHeader = () => {
    return (
      <div className="flex flex-row items-center">
        <div className="mr-2"><img src='/images/filter.svg' /></div>
        <Typography className="font-bold">{t('coupon.fitlerTitle')}</Typography>
      </div>
    )
  }

  return (
    <div className="coupon__filter">
      <Collapse
        defaultActiveKey={[]}
        expandIconPosition={'right'}
      >
        <Panel header={genHeader()} key="1">
          <Form
            form={form}
            name="coupon__filter"
            onFinish={onSubmitFilter}
            scrollToFirstError
          >
            <Form.Item
              name="secondary_categories"
              className="coupon__secondaryCategory-item"
            >
              <Checkbox.Group
                options={secondaryCategoriesOptions}
              />
            </Form.Item>
            <Form.Item
              name="service_categories"
            >
              <Checkbox.Group
                options={menuCategories}
              />
            </Form.Item>
            <Form.Item className="coupon__filter--submit-btn">
              <Button className="btn-secondary btn my-4 mr-4" htmlType="submit">
                {t('coupon.filterBtn')}
              </Button>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>
    </div>
  );
}
