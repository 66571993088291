import React, { useEffect } from "react"
import { Card, Row, Tabs, Col } from 'antd';
import { CouponCard } from '../CouponCard';
import { Filter } from './Filter';
import { t } from '../../helpers/translation'
import './styles.scss'

const { Meta } = Card;
const { TabPane } = Tabs;

export const Coupons = ({ storeId, coupons, couponsFirstVisit, couponsSecondVisit, onGetCoupons, onGetFirstVisitCoupons, onGetSecondVisitCoupons, onGetMenus }) => {
  const CATEGORY_EVERYONE = '1';
  const CATEGORY_NEW = '2';
  const CATEGORY_RETURN = '3';

  const [tabActive, setTabActive] = React.useState(CATEGORY_EVERYONE)

  const onChange = (key) => {
    setTabActive(`${key}`);
  }

  return (
    <Row className="w-full">
      <Col span={24}>
        <Tabs onChange={onChange} activeKey={tabActive} centered className="mirror__tab-info coupon__tab-info">
          <TabPane tab={t("coupon.regular")} key={CATEGORY_EVERYONE}>
            <Filter tabPaneKey={CATEGORY_EVERYONE} onGetCoupons={onGetCoupons} onGetMenus={onGetMenus} activeTab={tabActive}/>
            {coupons.map((coupon, idx) => {
              return (
                <CouponCard coupon={coupon} key={`regular-${coupon.id}`} storeId={storeId} />
              )
            })}
          </TabPane>
          <TabPane tab={t("coupon.firstVisit")} key={CATEGORY_NEW}>
            <Filter tabPaneKey={CATEGORY_NEW} onGetCoupons={onGetFirstVisitCoupons} onGetMenus={onGetMenus} activeTab={tabActive}/>
            {couponsFirstVisit.map((coupon, idx) => {
              return (
                <CouponCard coupon={coupon} key={`first-${coupon.id}`} storeId={storeId} />
              )
            })}
          </TabPane>
          <TabPane tab={t("coupon.secondVisit")} key={CATEGORY_RETURN}>
            <Filter tabPaneKey={CATEGORY_RETURN} onGetCoupons={onGetSecondVisitCoupons} onGetMenus={onGetMenus} activeTab={tabActive}/>
            {couponsSecondVisit.map((coupon, idx) => {
              return (
                <CouponCard coupon={coupon} key={`second-${coupon.id}`} storeId={storeId} />
              )
            })}
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
}
