import React, { useState } from "react"
import { Card, Typography, Button } from 'antd';
import { get } from 'lodash'
import { t } from '../../helpers/translation'
import { currency } from '../../utils/number'
import { addStylist, clearBooking, clearStylist, clearAddNewCouponMenuFlag, clearMenu, clearCoupon, setEditStylistFlag } from '../../helpers/bookings'
import './styles.scss'

const { Title, Paragraph } = Typography;

export const StylistCard = ({ stylist }) => {
  const [ellipsis, setEllipsis] = React.useState(true);
  const [counter, setCounter] = useState(0)
  const [expand, setExpand] = useState(false)

  const typoExpand = () => {
    setExpand(true)
    setCounter(!expand ? counter + 0 : counter + 1)
  }

  const typoClose = () => {
    setExpand(false)
    setCounter(!expand ? counter + 0 : counter + 1)
  }

  const disabledReservation = () => {
    return stylist && stylist?.notAllowNomination
  }

  const onClickName = () => {
    clearStylist();
    if (!disabledReservation()) { addStylist(stylist) }
    setEditStylistFlag(true)
    clearAddNewCouponMenuFlag();
  }
  
  const onOpenStaff = () => {
    let ref = null;
    const searchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(searchParams.entries());

    clearStylist();
    if (!disabledReservation()) { addStylist(stylist) }
    setEditStylistFlag(true)
    clearAddNewCouponMenuFlag();
    if (params.ref) { ref = params.ref }
    if (searchParams.has('from_booking_schedule')) {
      window.location.href = `/reservation_users/stores/${stylist.storeId}/bookings/schedule`
    } else {
      window.location = `/reservation_users/staffs/${stylist.id}${ref ? `?ref=${ref}` : '' }`
    }
  }

  return (
    <Card className="stylist__card my-4" bordered={false}>
      <div className="flex flex-row h-full">
        <div className="stylist__avatar w-2/5" style={{ backgroundImage: `url("${get(stylist, 'avatar')}")` }} />
        <div className="p-4 w-3/5">
          <a href={`/reservation_users/staffs/${stylist.id}/ec`} onClick={onClickName}>
            <Title level={5}>{get(stylist, 'displayName')}</Title>
          </a>
          
          <Typography className="stylist__year">{t('staff.staffHistoryTitle', { count: get(stylist, 'staffHistory') })}</Typography>
          <div className="pl-4 py-2 w-full">
            <div className="comment-box" key={counter}>
              <Paragraph className="stylist__comment" ellipsis={ellipsis ? { rows: 2, expandable: true,  onExpand: typoExpand, symbol: t('common.expandEllips') } : false}>
                {get(stylist, 'catchphrase')}
              </Paragraph>
            </div>
            {expand && <a onClick={typoClose}>{t('staff.close')}</a>}
          </div>
          { get(stylist, 'nominated?') && 
            <Typography className="gray-777">
              {t('staff.nominationFeeValue', { fee: currency(get(stylist, 'nominationFeeValue')) })}
            </Typography>
          }

          <Button
            className="btn-default btn mt-4"
            onClick={onOpenStaff}
            disabled={disabledReservation()}
          >
            {t('staff.bookingBtn')}
          </Button>
        </div>
      </div>
    </Card>
  );
}
