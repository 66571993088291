import React, {useEffect, useState} from "react"
import { Tabs } from 'antd';
import { Info } from './Info';
import { Stylist } from './Stylist';
import { Coupons } from '../../Coupons';
import { t } from '../../../helpers/translation'
import { getCoupons, fetchMenus } from '../../../services/coupons'
import './styles.scss'
import { getMenus } from "../../../helpers/bookings";
import BookingMenuList from "../../../ec_users_containers/BookingMenu/BookingMenuList";

const { TabPane } = Tabs;

export const TabInfo = ({ store, allCoupons, activeTab, staffs, menuIds, couponIds }) => {
  const [tabActive, setTabActive] = React.useState(activeTab || '1')
  const [coupons, setCoupons] = React.useState(allCoupons)
  const [menus, setMenus] = React.useState(store.menus)
  const [firstVisitCoupons, setFirstVisitCoupons] = React.useState(allCoupons)
  const [secondVisitCoupons, setCecondVisitCoupons] = React.useState(allCoupons)
  const [selectedMenus, setSelectedMenus] = useState([])
  const [filteredCoupons, setFilteredCoupons] = React.useState([])
  const [filteredFirstVisitCoupons, setFilteredFirstVisistCoupons] = React.useState([])
  const [filteredSecondVisitCoupons, setFilteredSecondVisitCoupons] = React.useState([])

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    const newFilteredCoupons = coupons?.filter(item => item.primaryCategory === 'category_everyone')
    setFilteredCoupons(newFilteredCoupons)
  }, [coupons])
  
  useEffect(() => {
    const newFilteredFirstVisitCoupons = firstVisitCoupons?.filter(item => item.primaryCategory === 'category_new')
    setFilteredFirstVisistCoupons(newFilteredFirstVisitCoupons)
  }, [firstVisitCoupons])
  
  useEffect(() => {
    const newFilteredSecondVisitCoupons = secondVisitCoupons?.filter(item => item.primaryCategory === 'category_return')
    setFilteredSecondVisitCoupons(newFilteredSecondVisitCoupons)
  }, [secondVisitCoupons])

  useEffect(() => {
    setSelectedMenus(getMenus() || []);
  }, [])

  const onSetTabActive = (key) => {
    setTabActive(`${key}`);
  }

  const onChange = (key) => {
    if (key === '4') {
      return window.location = '/'
    }
    setTabActive(`${key}`);
  }

  const onGetMenus = async (fitler) => {
    const res = await fetchMenus({...fitler, store_id: store.id, filter_data: params.filter_data, coupon_ids: couponIds, menu_ids: menuIds, is_stored: true, from_booking_schedule: true});
    if (res) {
      setMenus(res.data);
    }
  }

  const onGetCoupons = async (fitler) => {
    const res = await getCoupons({...fitler, store_id: store.id, filter_data: params.filter_data, coupon_ids: couponIds, menu_ids: menuIds, is_stored: true, from_booking_schedule: true});
    if (res) {
      setCoupons(res.data);
    }
  }

  const onGetFirstVisitCoupons = async (fitler) => {
    const res = await getCoupons({...fitler, store_id: store.id, primary_category: 'category_new', from_booking_schedule: true });
    if (res) {
      setFirstVisitCoupons(res.data);
    }
  }

  const onGetSecondVisitCoupons = async (fitler) => {
    const res = await getCoupons({...fitler, store_id: store.id, primary_category: 'category_return', from_booking_schedule: true });
    if (res) {
      setCecondVisitCoupons(res.data);
    }
  }

  return (
    <Tabs onChange={onChange} activeKey={tabActive} centered className="mirror__tab-info my-6">
      <TabPane tab={t("common.storeInfo")} key="1" className="px-4">
        <Info store={store} onSetTabActive={onSetTabActive} />
      </TabPane>
      <TabPane tab={t("common.styleList")} key="2" className="px-4">
        <Stylist store={store} stylists={staffs} onSetTabActive={onSetTabActive} />
      </TabPane>
      <TabPane tab={t("common.couponList")} key="3">
        <Coupons
          storeId={store.id}
          coupons={filteredCoupons}
          couponsFirstVisit={filteredFirstVisitCoupons}
          couponsSecondVisit={filteredSecondVisitCoupons}
          onGetCoupons={onGetCoupons}
          onGetFirstVisitCoupons={onGetFirstVisitCoupons}
          onGetSecondVisitCoupons={onGetSecondVisitCoupons}
          onGetMenus={onGetMenus}
        />
        <BookingMenuList
          storeId={store.id}
          menus={menus}
          selectedMenus={selectedMenus}
          onMenuSelected={setSelectedMenus}
        />
      </TabPane>
      <TabPane tab={t("common.popularList")} key="4" />
    </Tabs>
  );
}
