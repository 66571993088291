import React from "react"
import { Collapse, Typography, Divider } from 'antd';

import { t } from '../../../helpers/translation'
import { sumBy } from 'lodash'
import { currency, convertMinsToHour } from '../../../utils/number'
import { CouponCard } from '../CouponCard'
import './styles.scss'
import { removeCoupon, removeMenu } from "../../../helpers/bookings";
import MenuCard from "../../../ec_users_containers/BookingMenu/MenuCard";

const { Panel } = Collapse;

// export const CouponMenu = ({coupons, selectedMenus, storeId, updateCouponList, updateMenuList}) => {
export const CouponMenu = ({coupons, selectedMenus, storeId, updateCouponMenuList}) => {
  const [totalTreatmentTime, setTotalTreatmentTime] = React.useState(0);
  const [totalTreatmentPrice, setTotalTreatmentPrice] = React.useState(0);
  const [selectedCouponMenuSummary, setSelectedCouponMenuSummary] = React.useState(null);
  const [menuShowMark, setMenuShowMark] = React.useState(false);

  const genHeader = () => {
    return (
      <div>
        <Typography className="font-bold">{t('booking.selectedCouponMenu')}</Typography>
        <Typography className="text-xs">{selectedCouponMenuSummary}</Typography>
        <Typography className="text-xs">
          {currency(totalTreatmentPrice, menuShowMark)}
          <span>
            { t('common.taxInclude') }
          </span>
        </Typography>
      </div>
    )
  }

  React.useEffect(() => {
    const menuCoupons = coupons?.filter((coupon) => coupon?.defineCategory)
    const totalTime = sumBy(selectedMenus, 'requiredTime') + sumBy(menuCoupons, 'treatmentDuration');
    const totalPrice = sumBy(selectedMenus, 'priceWithTax') + sumBy(menuCoupons, 'price');
    const menuSummary = selectedMenus?.map((menu) => menu.name).join('+')
    const couponSummary = coupons?.map((coupon) => coupon.name).join('+')
    const menuHasMark = selectedMenus?.find((el) => el.isShowMark)
    setMenuShowMark(!!menuHasMark)
    setTotalTreatmentTime(totalTime);
    setTotalTreatmentPrice(totalPrice);
    setSelectedCouponMenuSummary([menuSummary, couponSummary].filter(Boolean).join('+'))
  }, [selectedMenus, coupons])

  const onDeleteCoupon = (coupon)  => {
    removeCoupon(coupon)
    // updateCouponList(coupons.filter((c) => c.id != coupon.id))
    updateCouponMenuList({
      coupons: coupons.filter((c) => c.id != coupon.id),
      selectedMenus: selectedMenus
    })
  }

  const onDeleteMenu = (menu)  => {
    removeMenu(menu)
    // updateMenuList(selectedMenus.filter((m) => m.id != menu.id))
    updateCouponMenuList({
      coupons: coupons,
      selectedMenus: selectedMenus.filter((m) => m.id != menu.id)
    })
  }

  return (
    <div className="coupon__menu px-4 mb-4">
      <Collapse
        defaultActiveKey={['1']}
        expandIconPosition={'right'}
      >
        <Panel header={genHeader()} key="1">
          {coupons?.map((coupon, index) => (
            <React.Fragment key={coupon.id}>
              <CouponCard coupon={coupon} onDelete={ () => onDeleteCoupon(coupon) } />
            </React.Fragment>
          ))}
          {selectedMenus?.map((menu, index) => (
            <React.Fragment key={menu.id}>
              <MenuCard menu={menu} onDeleteMenu={() => { onDeleteMenu(menu) }}/>
            </React.Fragment>
          ))}
        </Panel>
      </Collapse>
      <div className="px-4 py-2 text-gray-777 border bg-white rounded-br rounded-bl">{t('booking.totalTreatmentTime', { totalHours: convertMinsToHour(totalTreatmentTime) })}</div>
    </div>
  );
}
