import React from 'react'
import { StylistCard } from '../../reservation_components/BookingInfo/StylistCard';
import { NavStep } from '../../reservation_components/BookingInfo/NavStep';
import { CouponMenu } from '../../reservation_components/BookingInfo/CouponMenu';
import { Schedule } from '../../reservation_components/BookingInfo/Schedule';
import { getSchedule, 
         clearBooking, 
         getStylist, 
         getCoupon, 
         getMenus, 
         addStore, 
         addStylist, 
         addMenu, 
         addCoupon, 
         getAddCouponMenuFlag,
         clearSchedule,
         clearStore,
         getEditStylistFlag } from '../../helpers/bookings'
import { changeReservation } from '../../services/bookings'
import { addSchedule } from '../../helpers/bookings'
import {fetchMenus, getCoupons} from "../../services/coupons";
import { Button, notification } from 'antd';
import { t } from '../../helpers/translation';
import { get, sumBy } from 'lodash'
import moment from 'moment';

const DATE_KEY_FORMAT = 'YYYYMMDD';
const TIME_FORMAT = 'HH:mm'; 

const BookingScheduleChange = ({ store, reservation, staff }) => {
  const [stylist, setStylist] = React.useState(getStylist() ?? reservation.staff)
  // const [coupons, setCoupons] = React.useState([])
  // const [selectedMenus, setSelectedMenus] = React.useState([])
  const [couponMenuData, setCouponMenuData] = React.useState({
    coupons: [],
    selectedMenus: []
  });
  const [schedule, setSchedule] = React.useState(getSchedule())
  const [saving, setSaving] = React.useState(false)

  React.useEffect(async () => {

    clearSchedule();
    clearStore();
  
    addStore(store)

    if (reservation.menus && !getEditStylistFlag()) {
      addStylist(reservation.staff)
      setStylist(reservation.staff)
    }
   
    if (reservation.menus && !getAddCouponMenuFlag()) {
      addMenu(reservation.menus)
    }

    if (reservation.coupons[0] && !getAddCouponMenuFlag()) {
      addCoupon(reservation.coupons[0])
    }
    
    if (!isStylistChanged()) {
      const currentSchedule = {
        day: moment(reservation.dateVisitAt).format(DATE_KEY_FORMAT),
        time: reservation.timeVisitFrom
      }
      addSchedule(currentSchedule)
      setSchedule(currentSchedule)
    }

    const storageCoupons = getCoupon() || []
    const storageCouponIds = _.map(storageCoupons, (cp) => (cp.id))
    const filteredStaffId = stylist?.isVirtual ? null : stylist?.id;
    const storageMenus = getMenus() || []
    const storageMenuIds = _.map(storageMenus, (mn) => (mn.id))
    await Promise.all([getCoupons({ store_id: store.id, staff_id: filteredStaffId, ids: storageCouponIds, find_by_ids: true }),
                       fetchMenus({ store_id: store.id, staff_id: filteredStaffId, ids: storageMenuIds, find_by_ids: true })
                ]).then(([couponsRes, menusRes]) => {
                    const data = {
                      coupons: couponsRes?.data ?? [],
                      selectedMenus: menusRes?.data ?? []
                    }
                    setCouponMenuData(data)
                });
  }, [])

  const isStylistChanged = () => {
    return get(stylist, 'id') !== get(reservation, 'staff.id')
  }

  const onChangeSchedule = () => {
    setSchedule(getSchedule());
  }

  const onChangeMenuCoupon = () => {
    if (stylist) {
      window.location.href = `/reservation_users/staffs/${stylist.id}?ref=/reservation_users/stores/${store.id}/bookings/${reservation.id}/change`
    } else {
      window.location.href = `/reservation_users/stores/${store.id}?active_tab=2&ref=/reservation_users/stores/${store.id}/bookings/${reservation.id}/change`
    }
  }

  const handleDeleteCouponMenu = (type, ids) => {
    if (type === 'coupon') {
      const currentReservationCouponIds = reservation?.reservationCoupons.map((item) => item.couponId)
      const deletedCouponIds = currentReservationCouponIds.filter((couponId) => !ids.includes(couponId));
      if (ids.length === 0) { return reservation?.reservationCoupons.map((item) => item.id); }
      return reservation?.reservationCoupons.filter((item) => deletedCouponIds.includes(item.couponId)).map((item) => item.id);
    } else {
      const currentReservationMenuIds = reservation?.reservationMenus.map((item) => item.menuId)
      const deletedMenuIds = currentReservationMenuIds.filter((menuId) => !ids.includes(menuId));
      if (ids.length === 0) { return reservation?.reservationMenus.map((item) => item.id); }
      return reservation?.reservationMenus.filter((item) => deletedMenuIds.includes(item.menuId)).map((item) => item.id);
    }
  }

  const onChange = async() => {
    const menuIds = couponMenuData.selectedMenus?.map(item => item.id)
    const couponIds = couponMenuData.coupons?.map(item => item.id)
    const deletedCouponIds = handleDeleteCouponMenu('coupon', couponIds)
    const deletedMenuIds = handleDeleteCouponMenu('menu', menuIds)
    const timeRequired = sumBy(couponMenuData.selectedMenus, 'requiredTime') + sumBy(couponMenuData.coupons, 'treatmentDuration')
    const timeVisitTo = moment(`${schedule.time}`, TIME_FORMAT).add(timeRequired, 'minutes').format(TIME_FORMAT)

    const data = {
      reservation:
        {
          date_visit_at: moment(schedule.day).format('L'),
          time_visit_from: schedule.time,
          time_visit_to: timeVisitTo,
          time_required: timeRequired,
          coupon_ids: couponIds,
          staff_id: stylist?.id,
          store_id: store.id,
          menu_ids: menuIds,
          deleted_coupons: deletedCouponIds,
          deleted_menus: deletedMenuIds
        }
    }

    let res
    try {
      setSaving(true)
      res = await changeReservation(reservation.id, data)
      clearBooking();
      window.location.href = `/reservation_users/stores/${store.id}/bookings/${res.data.reservation.id}/completed`
    } catch (err) {
      setSaving(false);
      notification.error({
        message: get(err, 'response.data.message', t('common.system_error')),
      });
    }
  }

  return (
    <>
      <NavStep step={2} title={t('booking.selectScheduleTitle')}/>
      <CouponMenu
        coupons={couponMenuData.coupons} selectedMenus={couponMenuData.selectedMenus} storeId={store.id}
        updateCouponMenuList={setCouponMenuData}
        // updateCouponList={setCoupons} updateMenuList={setSelectedMenus}
      />
      <div className="flex space-x-1 w-full px-4">
        <Button className="btn-outline-secondary btn btn-small" onClick={() => onChangeMenuCoupon()}>
          <span className="font-bold">{t('booking.editCoupon')}</span>
        </Button>
        <Button className="btn-outline-secondary btn btn-small" onClick={() => onChangeMenuCoupon()}>
          <span className="font-bold">{t('booking.editMenu')}</span>
        </Button>
      </div>
      <StylistCard stylist={stylist} store={store} reservationId={reservation.id} />
      <Schedule
        staffCapacity={store.staffCapacity}
        selectedDate={reservation.dateVisitAt}
        timeVisitFrom={reservation.timeVisitFrom}
        storeId={store.id}
        stylist={stylist}
        selectedMenus={couponMenuData.selectedMenus}
        coupons={couponMenuData.coupons}
        isUpdate={true}
        onChangeSchedule={onChangeSchedule}
        reservationId={reservation.id}
      />
      <div className="px-4 sticky bottom-20 w-full z-10 mb-8">
        <Button className="btn-default btn" onClick={onChange} disabled={saving || !schedule || !couponMenuData.selectedMenus}>
          {t('booking.confirmEditSchedule')}
        </Button>
      </div>
    </>
  )
}

export default BookingScheduleChange
